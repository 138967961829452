import React, { useEffect, useState, useContext } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import { Tag, Modal, Select, Form as AntForm } from "antd";
import moment from "moment";
import "moment/locale/mn"; // Import Mongolian locale for moment
import { AuthContext } from "contexts";
import { instance } from "utils/axios";
import { QRCodeSVG } from "qrcode.react";
import { useNavigate } from "react-router-dom";

moment.locale("mn");

export default function ScheduleManagement() {
  const authContext = useContext(AuthContext);
  const { action } = authContext;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const [form] = AntForm.useForm();
  const [showQRCode, setShowQRCode] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  useEffect(() => {
    action.handleSidebarKey("schedule");
  }, []);

  const fetchData = (date1, date2) => {
    setLoading(true);
    instance({
      method: "get",
      url: `/schedules/by_date?date1=${date1}&date2=${date2}`,
    })
      .then((res) => {
        setData(
          res.data.map((item) => ({
            data: item,
            id: item.id,
            title: `Title${item.id}`,
            start: item.startTime,
            end: item.endTime,
            type: item.type,
          }))
        );
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const handleDatesSet = (dateInfo) => {
    const startDate = dateInfo.view.currentStart;
    const endDate = dateInfo.view.currentEnd;

    const adjustedStartDate = moment(startDate).startOf("week");
    const adjustedEndDate = moment(endDate).endOf("week");

    const formattedStartDate = moment(adjustedStartDate).format("yyyy-MM-DD");
    const formattedEndDate = moment(adjustedEndDate).format("yyyy-MM-DD");

    fetchData(formattedStartDate, formattedEndDate);
  };
  const handleEventClick = (clickInfo) => {
    let data = clickInfo.event.extendedProps.data;
    setSelectedSchedule({
      id: data.id,
      facilityName: data.facility.name,
      courseName: data.course.name,
      studyGroupName: data.study_group.name,
      teacherName: data.system_user.firstName,
      startTime: moment(data.startTime).format("HH:mm"),
      endTime: moment(data.endTime).format("HH:mm"),
    });
    setShowQRCode(true);
    navigate(`/schedule/${data.id}`);
  };

  const handleCloseModal = () => {
    setShowQRCode(false);
    form.resetFields();
  };

  const renderEventContent = (eventInfo) => {
    const data = eventInfo.event.extendedProps.data;

    return (
      <Tag
        onClick={() => handleEventClick(eventInfo)}
        className="flex p-1 break-words whitespace-normal text-wrap"
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
      >
        <Tag
          className="items-center break-words whitespace-normal "
          color="green"
        >
          {data.startTime ? moment(data.startTime).format("HH:mm") : ""}-
          {data.endTime ? moment(data.endTime).format("HH:mm") : ""}
        </Tag>
        <Tag
          color="blue"
          className="w-full text-xs break-words whitespace-normal md:text-sm"
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {data.course.name}, {data.study_group.name}, {data.facility.name},
          {data.system_user.firstName}
        </Tag>
      </Tag>
    );
  };

  return (
    <div className="p-4 m-4 bg-white rounded-lg shadow ">
      <h1 className="mb-4 text-center">Хуваарь Удирдлага</h1>
      <div className="m-2 h-[70vh]">
        <FullCalendar
          height="100%"
          firstDay={1}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            momentPlugin,
          ]}
          initialView="dayGridMonth"
          locale="mn"
          buttonText={{
            today: "Өнөөдөр",
            month: "Сар",
            week: "7 Хоног",
            day: "Өдөр",
            list: "Жагсаалт",
          }}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          events={data}
          eventContent={renderEventContent}
          editable={true}
          selectable={true}
          datesSet={handleDatesSet}
        />
      </div>
      {showQRCode && (
        <Modal
          title={"Qr code"}
          open={showQRCode}
          onCancel={handleCloseModal}
          footer={null}
          closeIcon={null}
          className="flex items-center justify-center text-center"
          width={400}
        >
          <div className="flex flex-col gap-3 m-6 mt-0">
            <div className="grid grid-cols-2 gap-2 pb-3 ">
              <Tag color={"blue"} className="text-base">
                Facility:{selectedSchedule?.facilityName}
              </Tag>
              <Tag color={"green"} className="text-base">
                Course:{selectedSchedule?.courseName}
              </Tag>
              <Tag color={"orange"} className="text-base">
                Study_group:{selectedSchedule?.studyGroupName}
              </Tag>
              <Tag color={"purple"} className="text-base">
                Teacher:{selectedSchedule?.teacherName}
              </Tag>
            </div>
            <QRCodeSVG
              value={`schedule_${selectedSchedule.id}`}
              style={{ width: "380px", height: "390px" }}
            />
          </div>
        </Modal>
      )}

      {contextHolder}
    </div>
  );
}
