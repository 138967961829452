import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Input,
  TimePicker,
  DatePicker,
  Select,
  Tag,
  Form as AntForm,
} from "antd";
import { MainTable, Form } from "components";

import { SearchOutlined, FileExcelOutlined } from "@ant-design/icons";
import moment from "moment";
import { instance } from "utils/axios";

const { Option } = Select;
const { RangePicker } = DatePicker;

export default function CourseSchedule() {
  const [form] = AntForm.useForm();
  const [currentPage, setCurrentPage] = useState(0);
  const [date1, setDate1] = useState(moment().startOf("week"));
  const [date2, setDate2] = useState(moment().endOf("week"));
  const [columns, setColumns] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCell, setSelectedCell] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [courses, setCourses] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [editData, setEditData] = useState(null);
  const [type, setType] = useState(null);
  const [period, setPeriod] = useState(1);

  useEffect(() => {
    fetchSchedule();
    fetchTeachers("/teachers", setTeachers);
    fetchData("/courses", setCourses);
    fetchData("/facilities", setFacilities);
  }, [date1, date2]);

  useEffect(() => {
    fetchData(`type/groups?type=${type}`, setGroups);
  }, [type]);

  const time = [
    { startTime: "08:30", endTime: "09:00", duration: "30", id: "1" },
    { startTime: "09:00", endTime: "09:30", duration: "30", id: "2" },
    { startTime: "09:30", endTime: "10:00", duration: "30", id: "3" },
    { startTime: "10:00", endTime: "10:30", duration: "30", id: "4" },
    { startTime: "10:30", endTime: "11:00", duration: "30", id: "5" },
    { startTime: "11:00", endTime: "11:30", duration: "30", id: "6" },
    { startTime: "11:30", endTime: "12:00", duration: "30", id: "7" },
    { startTime: "12:00", endTime: "12:30", duration: "30", id: "8" },
    { startTime: "12:30", endTime: "13:00", duration: "30", id: "9" },
    { startTime: "13:00", endTime: "13:30", duration: "30", id: "10" },
    { startTime: "13:30", endTime: "14:00", duration: "30", id: "11" },
    { startTime: "14:00", endTime: "14:30", duration: "30", id: "12" },
    { startTime: "14:30", endTime: "15:00", duration: "30", id: "13" },
    { startTime: "15:00", endTime: "15:30", duration: "30", id: "14" },
    { startTime: "15:30", endTime: "16:00", duration: "30", id: "15" },
    { startTime: "16:00", endTime: "16:30", duration: "30", id: "16" },
    { startTime: "16:30", endTime: "17:00", duration: "30", id: "17" },
    { startTime: "17:00", endTime: "17:30", duration: "30", id: "18" },
  ];

  const handleCreateSchedule = (values) => {
    const startDateTime = moment(
      `${moment(selectedCell?.date).format("YYYY-MM-DD")} ${
        selectedCell?.row?.startTime
      }`,
      "YYYY-MM-DD HH:mm"
    );

    const endDateTime = moment(
      `${moment(selectedCell?.date).format("YYYY-MM-DD")} ${
        selectedCell?.row?.endTime
      }`,
      "YYYY-MM-DD HH:mm"
    );

    const data =
      editData === null
        ? {
            ...values,
            startTime: startDateTime,
            endTime: endDateTime,
            period: period,
          }
        : {
            ...values,
            startTime: startDateTime,
            endTime: endDateTime,
            id: editData.id,
          };
    const url = editData === null ? `/schedule` : `/schedule/${editData.id}`;

    const method = editData === null ? "post" : "put";

    instance({
      method: method,
      url: url,
      data: data,
    })
      .then(() => {
        fetchSchedule();
        closeModal();
      })
      .catch((error) => console.error("Failed to create", error));
  };

  const fetchData = (endpoint, setState) => {
    instance
      .get(endpoint)
      .then((response) => {
        setState(
          response.data.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      })
      .catch((error) => console.error(`Failed to fetch ${endpoint}`, error));
  };

  const fetchSchedule = () => {
    instance({
      method: "get",
      url: `/schedules/by_date?date1=${
        date1
          ? moment(date1).format("yyyy-MM-DD")
          : moment().format("yyyy-MM-DD")
      }&date2=${
        date2
          ? moment(date2).format("yyyy-MM-DD")
          : moment().format("yyyy-MM-DD")
      }`,
    })
      .then((response) => {
        setSchedules(response?.data);
        generateDateColumns(response?.data);
      })
      .catch((error) => console.error(`Failed to fetch schedule`, error));
  };

  const fetchTeachers = (endpoint, setState) => {
    instance
      .get(endpoint)
      .then((response) => {
        setState(
          response.data.map((item) => ({
            id: item.id,
            firstName: item.firstName,
          }))
        );
      })
      .catch((error) => console.error(`Failed to fetch ${endpoint}`, error));
  };

  const fields = [
    {
      label: "Course",
      name: "courseId",
      type: "select",
      rules: [{ required: true, message: "Хичээлийн нэр сонгоно уу!" }],
      className: "col-span-12  md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        placeholder: "Хичээлийн нэр сонгоно уу",
      },
    },
    {
      label: "Teacher name",
      name: "teacherId",
      type: "select",
      rules: [{ required: true, message: "Багшийн нэр сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        placeholder: "Багшын нэр сонгоно уу",
      },
    },

    {
      label: "Facility",
      name: "facilityId",
      type: "select",
      rules: [{ required: true, message: "Ангийн нэр сонгоно уу!" }],
      className: "col-span-12  md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        placeholder: "Танхим сонгоно уу",
      },
    },
    {
      label: "Group type",
      name: "grouptype",
      type: "select",
      rules: [{ required: true, message: "Бүлгийн төрөл сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        value: type,
        onChange: (value) => {
          setType(value);
          form.setFieldsValue({ groupId: null });
        },
        options: [
          { value: "Үндсэн", label: "Үндсэн" },
          { value: "Туслах", label: "Туслах" },
        ],
        placeholder: "Бүлгийн төрөл сонгоно уу",
      },
    },
    {
      label: "Study group",
      name: "groupId",
      type: "select",
      rules: [{ required: true, message: "Please select a study group!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      hidden: !type,
      inputProps: {
        className: "w-full",
        placeholder: "Select a study group",
      },
    },
  ];

  const generateDateColumns = (schedulesData) => {
    const dateColumns = [];
    const staticColumns = [
      {
        title: "No",
        dataIndex: "No",
        key: "No",
        fixed: true,
        width: 10,
        align: "center",
        render: (text, row, i) => <span>{currentPage + i + 1}</span>,
      },
      {
        title: "Start time",
        dataIndex: "startTime",
        key: "startTime",
        fixed: true,
        width: 30,
      },
      {
        title: "End time",
        dataIndex: "endTime",
        key: "endTime",
        fixed: true,
        width: 30,
      },
      {
        title: "Duration",
        dataIndex: "duration",
        key: "duration",
        fixed: true,
        width: 30,
      },
    ];
    let currentDate = moment(date1);

    while (currentDate.isSameOrBefore(date2, "day")) {
      const dateForColumn = currentDate.clone();
      dateColumns.push({
        title: currentDate.format("M-DD ddd"),
        dataIndex: currentDate.format("YYYY-MM-DD"),
        key: currentDate.format("YYYY-MM-DD"),
        align: "center",
        // width: 50,
        render: (text, row) => {
          const schedule = schedulesData?.find(
            (sched) =>
              moment(sched?.startTime).format("HH:mm") === row?.startTime &&
              moment(sched?.endTime).format("HH:mm") === row?.endTime &&
              moment(sched?.startTime).format("YYYY-MM-DD") ===
                dateForColumn.format("YYYY-MM-DD")
          );

          return schedule ? (
            <Tag
              key={`schedule-${schedule?.id}`}
              className="grid grid-cols-2 gap-1 "
              onClick={() => handleCellClick1(row, dateForColumn, schedule)}
            >
              <Tag color={"green"}>{schedule.course?.name}</Tag>
              <Tag color={"orange"}>{schedule.study_group?.name}</Tag>
              <Tag color={"blue"}>{schedule.facility?.name}</Tag>
              <Tag color={"purple"}>{schedule.system_user?.firstName}</Tag>
            </Tag>
          ) : (
            <Button
              type="link"
              onClick={() => handleCellClick(row, dateForColumn)}
            >
              Create
            </Button>
          );
        },
      });
      currentDate = currentDate.add(1, "day");
    }

    return setColumns([...staticColumns, ...dateColumns]);
  };

  const handleCellClick1 = (row, date, schedule) => {
    setEditData(schedule);
    setType(schedule.study_group.type);
    setSelectedCell({ row, date });
    setIsModalOpen(true);
    form.setFieldsValue({
      grouptype: schedule.study_group.type,
    });
  };

  const handleCellClick = (row, date) => {
    setSelectedCell({ row, date });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setType(null);
    setSelectedCell(null);
    setEditData(null);
    form.resetFields();
    setIsModalOpen(false);
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="grid grid-cols-3 gap-5 md:grid-cols-6">
        <RangePicker
          value={[date1, date2]}
          onChange={(dates) => {
            if (dates) {
              setDate1(dates[0]);
              setDate2(dates[1]);
            } else {
              setDate1(moment());
              setDate2(moment());
            }
          }}
        />
      </div>

      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={time}
          columns={columns}
          //   loading={loading}
          title={"Хичээлийн хуваарь үүсгэх"}
        />
      </div>
      <Modal
        title={editData ? "Хувиар засах" : "Хувиар нэмэх"}
        open={isModalOpen}
        destroyOnClose
        width={800}
        okText={editData ? "Засах" : "Хадгалах"}
        // confirmLoading={loading}
        onOk={form.submit}
        onCancel={closeModal}
        // cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleCreateSchedule}
          className="gap-4"
          editData={editData}
        >
          {fields.map((field) => {
            if (field.hidden) {
              return null;
            }
            return (
              <Form.Item
                key={field.name}
                label={field.label}
                name={field.name}
                rules={field.rules}
                className={field.className}
              >
                {field.type === "timepicker" ? (
                  <TimePicker
                    format="HH:mm"
                    placeholder="Select time"
                    className="w-full"
                  />
                ) : field.type === "select" ? (
                  <Select {...field.inputProps}>
                    {field.name === "teacherId"
                      ? teachers.map((teacher) => (
                          <Select.Option key={teacher.id} value={teacher.id}>
                            {teacher.firstName}
                          </Select.Option>
                        ))
                      : field.name === "groupId"
                      ? groups.map((group) => (
                          <Select.Option key={group.id} value={group.id}>
                            {group.name}
                          </Select.Option>
                        ))
                      : field.name === "facilityId"
                      ? facilities.map((facility) => (
                          <Select.Option key={facility.id} value={facility.id}>
                            {facility.name}
                          </Select.Option>
                        ))
                      : field.name === "courseId"
                      ? courses.map((course) => (
                          <Select.Option key={course.id} value={course.id}>
                            {course.name}
                          </Select.Option>
                        ))
                      : null}
                  </Select>
                ) : (
                  <Input {...field.inputProps} />
                )}
              </Form.Item>
            );
          })}
          {editData ? (
            ""
          ) : (
            <div className="col-span-12 mb-0 md:col-span-6">
              <div className="w-full font-normal text-[#85878E] text-xs pb-[6px]">
                Select period
              </div>
              <div className="flex justify-between w-full gap-2">
                <Button onClick={() => setPeriod(2)}>2nd period</Button>
                <Button onClick={() => setPeriod(3)}>3rd period</Button>
                <Button onClick={() => setPeriod(4)}>4th period</Button>
              </div>
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
}
