import React, { useEffect, useState } from "react";
import { Form as AntForm, Modal, Breadcrumb, Affix, Button } from "antd";
import { useParams, Link } from "react-router-dom";
import {
  CalculatorOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { instance } from "utils/axios";
import { Form } from "components";

const LessonAssignment = () => {
  const { courseName, courseId } = useParams();
  const [lessons, setLessons] = useState([]);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);
  const [studyGroups, setStudyGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [form] = AntForm.useForm();
  const fields = [
    {
      label: "Нэр",
      name: "name",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      component: <input className="w-full" />,
    },
    {
      label: "Код",
      name: "code",
      rules: [{ required: true, message: "Код оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Study group",
      name: "groupId",
      type: "select",
      rules: [{ required: true, message: "Сурагчдын грүпп сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: studyGroups,
      },
    },
    {
      label: "Тайлбар",
      name: "description",
      type: "textarea",
      className: "col-span-12 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Deadline",
      name: "deadline",
      type: "date",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Оноо",
      name: "point",
      type: "number",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
  ];
  useEffect(() => {
    fetchLessons();
    fetchData();
    fetchEntities("/groups", setStudyGroups);
  }, [courseId]);

  const fetchEntities = (endpoint, setState) => {
    instance
      .get(endpoint)
      .then((response) => {
        setState(
          response.data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      })
      .catch((error) => console.error(`Failed to fetch ${endpoint}`, error));
  };

  const fetchData = () => {
    instance({
      method: "get",
      url: `/assignments/${courseId}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(`fetchData assignment ${err}`);
      });
  };

  const fetchLessons = () => {
    instance({
      method: "get",
      url: `/lessons/${courseId}`,
    })
      .then((response) => {
        setLessons(response.data);
      })
      .catch((error) => console.error(`Failed to fetch `, error));
  };

  const handleAdd = (lesson) => {
    setEditData(null);
    setSelectedLesson(lesson);
    setOpen(true);
  };

  const handleEdit = (lesson, assignment) => {
    setEditData(assignment);
    setSelectedLesson(lesson);
    setOpen(true);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    if (editData) {
      instance({
        method: "put",
        url: `/assignment`,
        data: {
          ...values,
          id: editData.id,
          lessonId: selectedLesson.id,
          courseId: courseId,
        },
      })
        .then((res) => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: `/assignment`,
        data: {
          ...values,
          lessonId: selectedLesson.id,
          courseId: courseId,
        },
      })
        .then((res) => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };
  const handleCloseModal = () => {
    setEditData(null);
    setOpen(false);
    form.resetFields();
  };
  return (
    <div className="flex flex-col gap-4 px-5">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/assignment" className="text-3xl font-bold text-gray-800">
            Courses
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={`/assignment/${courseId}/${courseName}`}
            className="text-3xl font-bold text-gray-800"
          >
            {courseName}
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div>
        {lessons.length > 0 ? (
          lessons?.map((item, i) => {
            return (
              <div
                key={`lessons ${i}`}
                className="w-4/6 p-5 rounded-md border-[2px]"
              >
                <div className="flex justify-between">
                  <div className="flex justify-center gap-3">
                    <p className="w-10 h-10 text-2xl text-center bg-yellow-500 rounded-full">
                      <CalculatorOutlined />
                    </p>

                    <h2>{item?.name}</h2>
                  </div>
                  <div>
                    <Affix offsetTop={80}>
                      <Button
                        type="primary"
                        size="small"
                        className="flex items-center "
                        onClick={() => handleAdd(item)}
                      >
                        <PlusOutlined /> Даалгавар нэмэх
                      </Button>
                    </Affix>
                  </div>
                </div>
                <div className="grid grid-cols-3">
                  {data.filter((ass) => ass.lessonId === item.id).length > 0 ? (
                    data
                      .filter((ass) => ass.lessonId === item.id)
                      .map((ass, i) => (
                        <div
                          key={`ass ${i}`}
                          className="flex gap-2 text-center"
                        >
                          <p>{ass.name}</p>
                          <p
                            className="text-center cursor-pointer"
                            onClick={() => handleEdit(item, ass)}
                          >
                            <EditOutlined />
                          </p>
                        </div>
                      ))
                  ) : (
                    <div className="mt-5 text-lg text-center text-gray-500">
                      Даалгавар үүсээгүй байна
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="mt-5 text-lg text-center text-gray-500">
            Хичээлийн сэдэвүүд үүсээгүй байна
          </div>
        )}
      </div>
      <Modal
        title={
          <>
            <span>
              {editData ? "Даалгавар мэдээлэл засах" : "Шинэ Даалгавар"}
            </span>
            <p
              style={{
                fontSize: "14px",
                color: "gray",
                marginTop: "4px",
              }}
            >
              Энэ хэсэгт{" "}
              <span className="text-lg text-black">
                {" "}
                {selectedLesson?.name}{" "}
              </span>
              хичээлийн даалгаврын мэдээллийг оруулна уу.
            </p>
          </>
        }
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          className="gap-4"
        />
      </Modal>
    </div>
  );
};

export default LessonAssignment;
