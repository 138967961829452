import React, { useEffect, useState } from "react";
import {
  Button,
  Select,
  Tag,
  Breadcrumb,
  Modal,
  Input,
  Form as AntForm,
  Checkbox,
  Radio,
} from "antd";
import { EyeOutlined, FilterOutlined } from "@ant-design/icons";
import { MainTable } from "components";
import { instance } from "utils/axios";
import { Link, useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

const SheduleAttendance = () => {
  const { TextArea } = Input;
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState();
  const [students, setStudents] = useState();
  const [showQRCode, setShowQRCode] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [check, setCheck] = useState([]);
  const [attendance, setAttendance] = useState();
  const [note, setNote] = useState(null);
  const { id } = useParams();
  const [form] = AntForm.useForm();
  const [searchText, setSearchText] = useState("");
  const [onChanged, setOnChanged] = useState(false);
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    fetchSchedule();
  }, []);
  useEffect(() => {
    if (schedule) {
      fetchAttendance();
    }
  }, [schedule]);
  useEffect(() => {
    if (schedule && schedule.groupId) {
      fetchGroupUser();
    }
  }, [schedule?.groupId]);

  const handleCheckboxChange = (record, checkedValues) => {
    setSelectedStatus("");
    setNote("");
    const checked = checkedValues.target.checked;
    setCheck((prev) => {
      if (checked) {
        return [...prev, record];
      } else {
        return prev.filter((item) => item.id !== record.id);
      }
    });
  };

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setCheck(students || []);
    } else {
      setCheck([]);
    }
  };

  const fetchSchedule = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/schedule/${id}`,
    })
      .then((res) => {
        setSchedule(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };
  const fetchAttendance = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/student/attendance/${id}`,
    })
      .then((res) => {
        setAttendance(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const fetchGroupUser = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/groupusers/by/${schedule.groupId}`,
    })
      .then((res) => {
        setStudents(
          res.data.map((user) => ({
            id: user.studentId,
            code: user.code,
            name: user.fullName,
            house: user.houseId,
            grade: user.grade,
          }))
        );
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const columns = [
    {
      title: (
        <Checkbox
          onChange={handleSelectAll}
          checked={students && check.length === students.length}
          indeterminate={
            students && check.length > 0 && check.length < students.length
          }
        />
      ),
      key: "checkboxGroup",
      width: 5,
      fixed: true,
      align: "center",
      render: (text, record) => (
        <Checkbox
          onChange={(checkedValues) =>
            handleCheckboxChange(record, checkedValues)
          }
          checked={check.some((item) => item.id === record.id)}
        />
      ),
    },
    {
      title: "Code",

      dataIndex: "code",
      key: "code",
      fixed: true,
      width: 50,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Нэр",
      dataIndex: "name",
      key: "name",
      fixed: true,
      width: 150,
      sorter: (a, b) => {
        const firstNameA = a.name.split(".")[1];
        const firstNameB = b.name.split(".")[1];
        return firstNameA.localeCompare(firstNameB);
      },
    },
    {
      title: "House",
      dataIndex: "house",
      key: "house",
      fixed: true,
      width: 50,
      sorter: (a, b) => a.house.localeCompare(b.house),
    },
    {
      title: "Анги",
      dataIndex: "grade",
      key: "grade",
      fixed: true,
      width: 50,
      sorter: (a, b) => a.grade - b.grade,
    },
    {
      title: " Ирц бүртгэх",
      filters: [
        { text: "Ирсэн", value: "Ирсэн" },
        { text: "Хоцорсон", value: "Хоцорсон" },
        { text: "Чөлөөтэй", value: "Чөлөөтэй" },
        { text: "Өвчтэй", value: "Өвчтэй" },
        { text: "Тасалсан", value: "Тасалсан" },
        { text: "Ирц бүртгэх", value: null },
      ],
      onFilter: (value, record) => {
        const studentAttendance = attendance?.find(
          (item) => item.student.id === record.id
        );

        if (value === null) {
          return !studentAttendance?.status;
        }
        return studentAttendance?.status === value;
      },
      dataIndex: "attendance",
      key: "attendance",
      fixed: true,
      width: 10,
      render: (a, b) => {
        const studentAttendance = attendance?.find(
          (item) => item.student.id === b.id
        );
        const status = studentAttendance?.status || "Ирц бүртгэх";
        const statusOptions = [
          { value: "Ирсэн", color: "green", bgColor: "#d4edda" },
          { value: "Чөлөөтэй", color: "purple", bgColor: "#d9b3ff" },
          { value: "Өвчтэй", color: "blue", bgColor: "#99ccff" },
          { value: "Хоцорсон", color: "yellow", bgColor: "#ffff99" },
          { value: "Тасалсан", color: "red", bgColor: "#ff9999" },
        ];

        return (
          <Select
            defaultValue="Ирц бүртгэх"
            value={status}
            onChange={(value) => handleSave(value, b, studentAttendance?.note)}
            bordered={false}
          >
            {statusOptions.map(({ value, color, bgColor }) => (
              <Select.Option key={value} value={value}>
                <Tag
                  style={{ transition: "background-color 0.3s" }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = bgColor)
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "transparent")
                  }
                  color={color}
                >
                  {value}
                </Tag>
              </Select.Option>
            ))}
          </Select>
        );
      },
    },

    {
      title: "Тайлбар",
      dataIndex: "note",
      key: "note",
      fixed: true,
      width: 50,
      render: (a, b) => {
        const studentAttendance = attendance?.find(
          (item) => item.student.id === b.id
        );

        return (
          <div className="relative flex items-center">
            <TextArea
              value={studentAttendance?.note}
              onChange={(e) => handleChangeNote(e.target.value, b)}
              placeholder="Тэмдэглэл оруулах"
              bordered={false}
              autoSize
              className="relative pr-20"
            />
            <button
              className={`${
                onChanged[b.id] ? "" : "hidden"
              } text-yellow-500 font-medium absolute right-1 cursor-pointer`}
              onClick={() => handleSave(studentAttendance?.status, b, note)}
            >
              Хадгалах
            </button>
          </div>
        );
      },
    },
  ];

  const handleChangeNote = (value, student) => {
    const updatedAttendance = attendance?.map((attend) =>
      attend?.student.id === student?.id ? { ...attend, note: value } : attend
    );
    setNote(value);
    setAttendance(updatedAttendance);
    setOnChanged((prevState) => ({
      ...prevState,
      [student?.id]: true,
    }));
  };

  const handleSave = (status, student, desc) => {
    const data = {
      code: check[0] ? check.map((item) => item?.code) : [student?.code],
      status: status,
      scheduleId: id,
      type: "Teacher",
      note: desc,
    };
    instance({
      method: "put",
      url: "/student/schedule/attendance",
      data: data,
    })
      .then(() => {
        setSearchText("");
        setCheck([]);
        setSelectedStudent(null);
        setSelectedStatus(null);
        setNote(null);
        setOnChanged(false);
        fetchAttendance();
      })
      .catch(() => {});
  };

  const handleQrModal = () => {
    setShowQRCode(true);
  };
  const handleCloseQRModal = () => {
    setShowQRCode(false);
    form.resetFields();
  };

  const filteredStudents = students?.filter((student) => {
    return (
      student.name.toLowerCase().includes(searchText.toLowerCase()) ||
      student.code.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/schedule" className="text-3xl font-bold text-gray-800">
            Хуваар
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={`/schedule/${id}`}
            className="text-3xl font-bold text-gray-800"
          >
            Хичээлийн ирц бүртгэх
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="flex gap-5 my-10">
        <div className="flex gap-2 pb-3 ">
          <Tag color={"blue"} className="text-base">
            Facility: {schedule?.facility.name}
          </Tag>
          <Tag color={"green"} className="text-base">
            Course: {schedule?.course.name}
          </Tag>
          <Tag color={"orange"} className="text-base">
            Study_group:{schedule?.study_group.name}
          </Tag>
          <Tag color={"purple"} className="text-base">
            Teacher: {schedule?.system_user.firstName}
          </Tag>
        </div>
        <Button
          type="primary"
          size="small"
          className="flex items-center text-base "
          onClick={handleQrModal}
        >
          <EyeOutlined /> Qr code
        </Button>
      </div>
      <div className="flex w-full gap-10 ">
        <div
          className={`border bg-gray-100 p-10 border-gray-200 gap-5  h-1/3 rounded-lg shadow ${
            check[0] ? "flex flex-col" : "hidden"
          } `}
        >
          <h3 className="text-center ">Сонгогдсон сурагчдын ирц бүртгэх</h3>
          <div className="flex flex-col gap-3 ">
            <Select
              value={selectedStatus}
              onChange={setSelectedStatus}
              placeholder="Статус сонгох..."
              style={{ width: "100%", marginBottom: 16 }}
            >
              <Select.Option value="Ирсэн">Ирсэн</Select.Option>
              <Select.Option value="Хоцорсон">Хоцорсон</Select.Option>
              <Select.Option value="Чөлөөтэй">Чөлөөтэй</Select.Option>
              <Select.Option value="Өвчтэй">Өвчтэй</Select.Option>
              <Select.Option value="Тасалсан">Тасалсан</Select.Option>
            </Select>

            <Input.TextArea
              value={note}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Тэмдэглэл оруулах"
            />

            <Button
              type="primary"
              size="small"
              className=""
              onClick={() => handleSave(selectedStatus, selectedStudent, note)}
            >
              Хадгалах
            </Button>
          </div>
          <div className="text-wrap">
            {check?.map((item, i) => {
              return <p key={`checked ${i}`}>{item.name}</p>;
            })}
          </div>
        </div>
        <div
          className={
            "border bg-white border-gray-200 rounded-lg shadow w-full "
          }
        >
          <h3 className="text-center ">
            {schedule?.study_group.name} группийн сурагчдын жагсаалт
          </h3>
          <div className="flex items-center gap-5 pb-5 pl-5">
            <p className="m-0">Сурагчын нэрээр хайх</p>
            <Input
              placeholder="Хайх..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              style={{ width: 250 }}
            />
          </div>

          <MainTable
            dataTable={filteredStudents}
            columns={columns}
            loading={loading}
          />
        </div>
      </div>

      <Modal
        title={"Qr code"}
        open={showQRCode}
        onCancel={handleCloseQRModal}
        footer={null}
        closeIcon={null}
        className="flex items-center justify-center text-center"
        width={400}
      >
        <div className="flex flex-col gap-3 m-6 mt-0">
          <div className="grid grid-cols-2 gap-2 pb-3 ">
            <Tag color={"blue"} className="text-base">
              Facility: {schedule?.facility.name}
            </Tag>
            <Tag color={"green"} className="text-base">
              Course: {schedule?.course.name}
            </Tag>
            <Tag color={"orange"} className="text-base">
              Study_group:{schedule?.study_group.name}
            </Tag>
            <Tag color={"purple"} className="text-base">
              Teacher: {schedule?.system_user.firstName}
            </Tag>
          </div>
          <QRCodeSVG
            value={`schedule_${schedule?.id}`}
            style={{ width: "380px", height: "390px" }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SheduleAttendance;
