import React, { useEffect, useState, useContext } from "react";
import { Button, DatePicker, Input, Tag, Select, Modal, message } from "antd";
import {
  SearchOutlined,
  ClearOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { MainTable } from "components";
import { instance } from "utils/axios";
import * as XLSX from "xlsx";
import moment from "moment";
import { AuthContext } from "contexts";
const { Option } = Select;
const { RangePicker } = DatePicker;
function AttendanceReport() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date1, setDate1] = useState(moment().startOf("week")); // Monday of current week
  const [date2, setDate2] = useState(moment().endOf("week"));
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [note, setNote] = useState("");
  const [selectedCode, setSelectedCode] = useState("");
  const [grade, setGrade] = useState("");
  const [house, setHouse] = useState("");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const { state, action } = useContext(AuthContext);
  useEffect(() => {
    action.handleSidebarKey("attendancesreport");
  }, []);

  useEffect(() => {
    fetchData();
  }, [date1, date2]);

  useEffect(() => {
    applyFilters();
  }, [searchText, grade, house, date1, date2, data]);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/report/attendance?date1=${
        date1
          ? moment(date1).format("yyyy-MM-DD")
          : moment().format("yyyy-MM-DD")
      }&date2=${
        date2
          ? moment(date2).format("yyyy-MM-DD")
          : moment().format("yyyy-MM-DD")
      }`,
    })
      .then((res) => {
        setData(res.data);
        setColumns(generateDynamicColumns(res.data));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  const applyFilters = () => {
    let filtered = [...data];
    if (searchText) {
      filtered = filtered.filter(
        (item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.id.toString().includes(searchText)
      );
    }
    if (grade) {
      filtered = filtered.filter((item) => item.grade === grade);
    }
    if (house) {
      // console.log(filtered);
      filtered = filtered.filter((item) => item.house === house);
    }
    setFilteredData(filtered);
  };

  const generateDynamicColumns = (data) => {
    if (!data.length) return [];

    const staticColumns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        fixed: true,
        width: 50,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: "Нэр",
        dataIndex: "name",
        key: "name",
        fixed: true,
        width: 150,
        sorter: (a, b) => {
          const firstNameA = a.name.split(".")[1];
          const firstNameB = b.name.split(".")[1];
          return firstNameA.localeCompare(firstNameB);
        },
      },
      {
        title: "House",
        dataIndex: "house",
        key: "house",
        fixed: true,
        width: 50,
        sorter: (a, b) => a.house.localeCompare(b.house),
      },
      {
        title: "Анги",
        dataIndex: "grade",
        key: "grade",
        fixed: true,
        width: 50,
        sorter: (a, b) => a.grade - b.grade,
      },
      {
        title: "И",
        dataIndex: "present",
        key: "present",
        fixed: true,
        width: 10,
        sorter: (a, b) => a.present - b.present,
      },
      {
        title: "Х",
        dataIndex: "late",
        key: "late",
        fixed: true,
        width: 10,
        sorter: (a, b) => a.late - b.late,
      },
      {
        title: "Ө",
        dataIndex: "sick",
        key: "sick",
        fixed: true,
        width: 10,
        sorter: (a, b) => a.sick - b.sick,
      },
      {
        title: "Ч",
        dataIndex: "excused",
        key: "excused",
        fixed: true,
        width: 10,
        sorter: (a, b) => a.excused - b.excused,
      },
      {
        title: "Т",
        dataIndex: "absent",
        key: "absent",
        fixed: true,
        width: 10,
        sorter: (a, b) => a.absent - b.absent,
      },
    ];
    const dynamicColumns = Object.keys(data[0])
      .filter((key) => key.match(/^\d{2}-\d{2}$/))
      .map((dateKey) => ({
        title: dateKey,
        dataIndex: dateKey,
        key: dateKey,
        sorter: (a, b) => a[dateKey].localeCompare(b[dateKey]),
        render: (status, row) => {
          let color;
          switch (status) {
            case "Ирсэн":
              color = "green";
              break;
            case "Хоцорсон":
              color = "orange";
              break;
            case "Өвчтэй":
              color = "blue";
              break;
            case "Чөлөөтэй":
              color = "purple";
              break;
            case "Тасалсан":
              color = "red";
              break;
            default:
              color = "default";
          }
          return (
            <Tag
              onClick={() => {
                // console.log(row.id);
                setSelectedDate(date1.year() + "-" + dateKey);
                setSelectedCode(row.id);
                openEditModal(status);
              }}
              color={color}
            >
              {status}
            </Tag>
          );
        },
      }));

    return [...staticColumns, ...dynamicColumns];
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Attendance Report");
    XLSX.writeFile(wb, "attendance_report.xlsx");
  };
  const openEditModal = (status) => {
    // setSelectedRecord(record);
    setSelectedStatus(status);
    // setNote(record.note || "")
    setIsEditModalVisible(true);
  };
  const handleSave = () => {
    const dataToSave = {
      code: selectedCode,
      status: selectedStatus,
      attendanceDate: selectedDate,
      type: "Teacher",
      note: note,
      startDate: selectedDate,
      endDate: selectedDate,
    };

    instance({
      method: "put",
      url: "/student/attendance/update", // Example API endpoint
      data: dataToSave,
    })
      .then(() => {
        message.success("Мэдээлэл амжилттай хадгалагдлаа");
        setIsEditModalVisible(false); // Close modal after saving
        fetchData(); // Refresh the data after saving
      })
      .catch(() => {
        message.error("Хадгалах үед алдаа гарлаа!");
      });
  };
  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
      <div className="grid grid-cols-3 gap-5 md:grid-cols-6">
        {/* <DatePicker value={date} onChange={(e) => setDate(e)} picker="month" /> */}
        <RangePicker
          value={[date1, date2]}
          onChange={(dates) => {
            if (dates) {
              setDate1(dates[0]);
              setDate2(dates[1]);
            } else {
              setDate1(moment());
              setDate2(moment());
            }
          }}
        />

        <Input
          placeholder="Код эсвэл Нэр"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />

        <Select
          placeholder="Сонгох анги"
          value={grade}
          onChange={(value) => setGrade(value)}
          allowClear
        >
          <Option value="">Бүгд</Option>
          <Option value="8">8-р анги</Option>
          <Option value="9">9-р анги</Option>
          <Option value="10">10-р анги</Option>
          <Option value="11">11-р анги</Option>
          <Option value="12">12-р анги</Option>
        </Select>

        <Select
          placeholder="Сонгох хаус"
          value={house}
          onChange={(value) => setHouse(value)}
          allowClear
        >
          <Option value="">Бүгд</Option>
          <Option value="Buhu">Buhu</Option>
          <Option value="Hawt">Hawt</Option>
          <Option value="Irve">Irve</Option>
          <Option value="Khant">Khant</Option>
          <Option value="Maza">Maza</Option>
          <Option value="Tsa">Tsa</Option>
          <Option value="Tsen">Tsen</Option>
          <Option value="Yang">Yang</Option>
        </Select>

        <div>
          <Button icon={<SearchOutlined />} onClick={fetchData} />
          <Button
            icon={<FileExcelOutlined />}
            onClick={exportToExcel}
            style={{ backgroundColor: "green", color: "white" }}
          />
        </div>
      </div>

      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={filteredData} // Use filtered data
          columns={columns} // Use dynamic columns
          loading={loading}
          title={"Ирцийн жагсаалт"}
        />
        <Modal
          title="Статус болон Тэмдэглэл засах"
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          onOk={handleSave}
        >
          <Select
            value={selectedStatus}
            onChange={setSelectedStatus}
            style={{ width: "100%", marginBottom: 16 }}
          >
            <Select.Option value="Ирсэн">Ирсэн</Select.Option>
            <Select.Option value="Хоцорсон">Хоцорсон</Select.Option>
            <Select.Option value="Чөлөөтэй">Чөлөөтэй</Select.Option>
            <Select.Option value="Өвчтэй">Өвчтэй</Select.Option>
            <Select.Option value="Тасалсан">Тасалсан</Select.Option>
          </Select>
          <Input.TextArea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Тэмдэглэл оруулах"
          />
        </Modal>
      </div>
    </div>
  );
}

export default AttendanceReport;
