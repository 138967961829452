import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Input,
  Checkbox,
  message,
  Select,
  Form as AntForm,
} from "antd";
import { instance } from "utils/axios";
import { Form, MainTable } from "components";
export default function GroupManagement() {
  const [groups, setGroups] = useState([]);
  const [students, setStudents] = useState([]);
  // const [groupedStudents, setGroupedStudents] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [data, setData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [grade, setGrade] = useState("");
  const [house, setHouse] = useState("");
  const [groupId, setGroupId] = useState();
  const [form] = AntForm.useForm();
  const [editForm] = AntForm.useForm();

  useEffect(() => {
    fetchGroups();
    // fetchStudents();
  }, []);
  useEffect(() => {
    if (groupId) fetchGroupedStudents();
  }, [grade, searchText, grade, groupId, house]);
  const fetchGroups = () => {
    setLoading(true);
    instance({
      method: "get",
      url: "/groups",
    })
      .then((res) => {
        setGroups(res.data);
      })
      .catch((err) => {
        message.error("Failed to load groups");
      })
      .finally(() => setLoading(false));
  };
  const fetchGroupedStudents = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/groupusers/${groupId}?grade=${grade}&houseId=${house}&searchText=${searchText}`,
    })
      .then((res) => {
        // console.log(res.data)
        setStudents(res.data);
      })
      .catch((err) => {
        message.error("Failed to load students");
      })
      .finally(() => setLoading(false));
  };

  const handleGroupSelect = (data) => {
    // setSelectedGroup(data.id);
    setGroupId(data.id);
    // fetchGroupedStudents(data.id);
  };

  const handleCheck = (check, row) => {
    // console.log(check)
    setLoading(true);
    if (check) {
      instance({
        method: "post",
        url: `/groupuser/add`,
        data: {
          studentId: row.studentId,
          groupId: groupId,
        },
      })
        .then(() => {
          fetchGroupedStudents(groupId);
        })
        .catch((err) => {
          message.error("Failed ");
        })
        .finally(() => setLoading(false));
    } else {
      instance({
        method: "delete",
        url: `/groupusers/${row.groupUserId}`,
      })
        .then(() => {
          fetchGroupedStudents(groupId);
        })
        .catch((err) => {
          message.error("Failed");
        })
        .finally(() => setLoading(false));
    }
  };

  const handleSubmit = (values) => {
    if (editData) {
      instance({
        method: "put",
        url: `/group`,
        data: {
          ...values,
          status: "active",
          id: editData.id,
        },
      })
        .then((res) => {
          fetchGroups();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      instance({
        method: "post",
        url: `/group`,
        data: { ...values, status: "active" },
      })
        .then((res) => {
          fetchGroups();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const handleCloseModal = () => {
    setEditData(null);
    setOpen(false);
    form.resetFields();
  };

  const columnsGroups = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Actions",
      key: "action",
      render: (text, row) => (
        <div className="space-x-2">
          <Button
            onClick={() => {
              setEditData(row);
              setEditOpen(true);
              editForm.setFieldsValue(row);
            }}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];
  const fields = [
    {
      label: "Name",
      name: "name",
      rules: [{ required: true, message: "Овог оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Code",
      name: "code",
      rules: [{ required: true, message: "КОд оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "Type",

      name: "type",
      type: "select",
      rules: [{ required: true, message: "Төлөв сонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        placeholder: "Type",
        options: [
          { label: "Үндсэн", value: "Үндсэн" },
          { label: "Туслах", value: "Туслах" },
        ],
      },
    },
    {
      label: "Grade",

      name: "grade",
      type: "select",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        placeholder: "Grade",
        options: [
          { label: "Бүгд", value: "" },
          { label: "8-р анги", value: "8" },
          { label: "9-р анги", value: "9" },
          { label: "10-р анги", value: "10" },
          { label: "11-р анги", value: "11" },
          { label: "12-р анги", value: "12" },
        ],
      },
    },
    {
      label: "Description",
      name: "description",
      className: "col-span-12  mb-0",
      type: "textarea",
      inputProps: {
        className: "w-full",
      },
    },
  ];

  const columnsGroupedStudents = [
    {
      title: "Овог нэр",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Код",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "House",
      dataIndex: "houseId",
      key: "houseId",
    },

    ...(groupId
      ? [
          {
            title: "Group",
            key: "groupId",
            render: (text, row) => (
              <Checkbox
                checked={row.groupUserId != null}
                onChange={(e) => handleCheck(e.target.checked, row)}
              />
            ),
            sorter: (a, b) => (a.groupUserId ? 1 : 0) - (b.groupUserId ? 1 : 0),
          },
        ]
      : []),
  ];
  const sortedStudents = [...students].sort((a, b) => {
    const checkedA = a.groupUserId != null ? 1 : 0;
    const checkedB = b.groupUserId != null ? 1 : 0;
    if (checkedA === checkedB) {
      return a.fullName.localeCompare(b.fullName);
    }
    return checkedB - checkedA;
  });

  const handleAdd = () => {
    setEditData(null);
    setOpen(true);
  };
  // const changeSelectKey =(value)=>{
  //   console.log(value)
  //   handleGroupSelect

  // }
  return (
    <div className="p-4 m-4 bg-white rounded-lg shadow">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <MainTable
          columns={columnsGroups}
          setSelectedKeys={handleGroupSelect}
          dataTable={groups}
          loading={loading}
          rowKey="id"
          // pagination={true}
          title={"Study group"}
          handleAdd={handleAdd}
          rowSelectionSingleShow={true}
          scroll={{ x: "max-content", y: `calc(100vh - 20vh)` }}
          // handleRefresh={}
        />
        <div>
          <div className="grid grid-cols-3 gap-2 mb-2 ">
            <Input
              placeholder="Name or Code"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              allowClear
            />
            <Select
              placeholder="Сонгох анги"
              value={grade}
              onChange={(value) => setGrade(value)}
              allowClear
            >
              <Select.Option value="">Бүгд</Select.Option>
              <Select.Option value="8">8-р анги</Select.Option>
              <Select.Option value="9">9-р анги</Select.Option>
              <Select.Option value="10">10-р анги</Select.Option>
              <Select.Option value="11">11-р анги</Select.Option>
              <Select.Option value="12">12-р анги</Select.Option>
            </Select>

            <Select
              placeholder="Сонгох хаус"
              value={house}
              onChange={(value) => setHouse(value)}
              allowClear
            >
              <Select.Option value="">Бүгд</Select.Option>
              <Select.Option value="Buhu">Buhu</Select.Option>
              <Select.Option value="Hawt">Hawt</Select.Option>
              <Select.Option value="Irve">Irve</Select.Option>
              <Select.Option value="Khant">Khant</Select.Option>
              <Select.Option value="Maza">Maza</Select.Option>
              <Select.Option value="Tsa">Tsa</Select.Option>
              <Select.Option value="Tsen">Tsen</Select.Option>
              <Select.Option value="Yang">Yang</Select.Option>
            </Select>
          </div>

          <MainTable
            columns={columnsGroupedStudents}
            dataTable={sortedStudents}
            scroll={{ y: `calc(100vh - 20vh)` }}
            loading={loading}
            rowKey="id"
            bordered
            title={() =>
              selectedGroup
                ? `${selectedGroup.name} Group Students`
                : "Students"
            }
          />
        </div>
      </div>

      <Modal
        title={editData ? "Групын мэдээлэл засах" : "Шинэ групп"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          // isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}
