import React, { useEffect, useState } from "react";
import { CodepenOutlined } from "@ant-design/icons";
import { instance } from "utils/axios";
import { Link } from "react-router-dom";

function Assignment() {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetchEntities("/courses", setCourses);
  }, []);

  const fetchEntities = (endpoint, setState) => {
    instance
      .get(endpoint)
      .then((response) => {
        setState(
          response.data.map((item) => ({
            value: item.id,
            label: item.name,
            code: item.code,
          }))
        );
      })
      .catch((error) => console.error(`Failed to fetch ${endpoint}`, error));
  };

  return (
    <div className="flex flex-col gap-4 px-5">
      <h1>Courses</h1>
      <div className="grid gap-5 md:grid md:grid-cols-5">
        {courses.length > 0 ? (
          courses.map((item, i) => {
            const color =
              item.status === "Хоцорсон"
                ? "yellow"
                : item.status === "Ирсэн"
                ? "green"
                : item.status === "Чөлөөтэй"
                ? "blue"
                : item.status === "Тасалсан"
                ? "red"
                : "default";
            return (
              <Link
                to={`/assignment/${item.value}/${item.label}`}
                key={`item ${i}`}
                className={`pl-3 pt- bg-yellow-500 shadow-xl rounded-l-2xl `}
              >
                <div className="flex flex-col gap-5 px-10 py-3 bg-white">
                  <div className="flex items-center justify-between">
                    <p>{item.label}</p>
                    <p className="text-[12px] text-gray-400 flex items-center">
                      <CodepenOutlined />
                      <span className="pl-1">{item.code}</span>
                    </p>
                  </div>
                </div>
              </Link>
            );
          })
        ) : (
          <div className="mt-5 text-lg text-center text-gray-500">
            Мэдээлэл олдсонгүй
          </div>
        )}
      </div>
    </div>
  );
}

export default Assignment;
