import React, { useEffect, useState } from "react";
import { MainTable, Form } from "components";
import {
  Button,
  DatePicker,
  Input,
  Tag,
  Select,
  Modal,
  message,
  Form as AntForm,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { instance } from "utils/axios";
import moment from "moment";

const AssignmentResponse = () => {
  const [form] = AntForm.useForm();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [groups, setGroups] = useState([]);
  const [group, setGroup] = useState(groups[0]?.study_group?.id);
  const [date1, setDate1] = useState(moment().startOf("week"));
  const [date2, setDate2] = useState(moment().endOf("week"));
  const [columns, setColumns] = useState([]);
  const [assResponse, setAssResponse] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedAss, setSelectedAss] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [point, setPoint] = useState(null);
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    fetchData("/assignments/byGroup", setGroups);
  }, []);

  useEffect(() => {
    setGroup(groups[0]?.study_group?.id);
  }, [groups]);

  useEffect(() => {
    if (group) {
      fetchData(`/groupusers/by/${group}`, setStudents);
    }
    fetchAssignments();
  }, [date1, date2, group]);

  useEffect(() => {
    if (assignments.length > 0) {
      fetchData(
        `/student/assignments/response?assignmentIds=${assignments.map(
          (item) => item.id
        )}`,
        setAssResponse
      );
      generateDateColumns(assignments);
    }
  }, [assignments]);

  useEffect(() => {
    generateDateColumns(assignments);
  }, [assResponse]);

  const fetchData = (endpoint, setState) => {
    instance
      .get(endpoint)
      .then((response) => {
        setState(response.data);
      })
      .catch((error) => console.error(`Failed to fetch ${endpoint}`, error));
  };
  const fetchAssignments = () => {
    instance
      .get(
        `/assignments/byDate?date1=${moment(date1).format(
          "yyyy-MM-DD"
        )}&date2=${moment(date2).format("yyyy-MM-DD")}&group=${group}`
      )
      .then((response) => {
        setAssignments(response.data);
      })
      .catch((error) => console.error(`Failed to fetch `, error));
  };

  const generateDateColumns = (assignments) => {
    const dynamicColumns = [];
    const staticColumns = [
      {
        title: "No",
        dataIndex: "No",
        key: "No",
        width: 40,
        align: "center",
        sorter: (a, b) => a.No - b.No,
        render: (text, row, i) => <span>{currentPage + i + 1}</span>,
      },
      {
        title: "ID",
        dataIndex: "code",
        key: "code",
        sorter: (a, b) => a.code - b.code,
      },
      {
        title: "Нэр",
        dataIndex: "fullName",
        key: "fullName",
        sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      },
    ];

    assignments.forEach((assignment, i) => {
      dynamicColumns.push({
        title: assignment.name,
        dataIndex: `assignment_${assignment.id}`,
        key: `assignment_${assignment.id}`,
        render: (text, row) => {
          const studentAss = assResponse?.find(
            (item) =>
              item.studentId === row.id && item.assignmentId === assignment.id
          );

          return studentAss ? (
            <Tag
              color={studentAss?.point ? "green" : "blue"}
              onClick={() => handleOpenModal(studentAss, row)}
              className="cursor-pointer "
            >
              {studentAss?.point ? `${studentAss.point} point` : "Review"}
            </Tag>
          ) : (
            <Tag color="yellow">Not sent</Tag>
          );
        },
      });
    });

    setColumns([...staticColumns, ...dynamicColumns]);
  };

  const handleSubmit = () => {
    setLoading(true);

    instance({
      method: "put",
      url: "/student/assignment/review",
      data: { point, id: selectedAss.id },
    })
      .then(() => {
        fetchData(
          `/student/assignments/response?assignmentIds=${assignments.map(
            (item) => item.id
          )}`,
          setAssResponse
        );
        handleCloseModal();
      })
      .catch((error) => console.error("Failed to create", error))
      .then(() => setLoading(false));
  };
  const handleOpenModal = (studentAss, row) => {
    setSelectedAss(studentAss);
    setSelectedStudent(row);
    setOpen(true);
  };
  const handleCloseModal = () => {
    setPoint(null);
    setOpen(false);
    form.resetFields();
  };

  const handleCopy = () => {
    if (selectedAss?.fileLink) {
      navigator.clipboard
        .writeText(selectedAss.fileLink)
        .then(() => {
          setCopy(true);
          message.success("Link copied to clipboard!");
          setTimeout(() => {
            setCopy(false);
          }, 2000);
        })
        .catch((error) => {
          console.error("Failed to copy text: ", error);
        });
    }
  };
  return (
    <div>
      <div>
        {" "}
        <RangePicker
          value={[date1, date2]}
          onChange={(dates) => {
            if (dates) {
              setDate1(dates[0]);
              setDate2(dates[1]);
            } else {
              setDate1(moment());
              setDate2(moment());
            }
          }}
        />
        <Select
          placeholder="Сонгох грүпп"
          value={group}
          onChange={(value) => setGroup(value)}
          allowClear
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.children?.toLowerCase().includes(input.toLowerCase())
          }
        >
          {groups?.map((item, i) => (
            <Option key={`groups ${i}`} value={item.study_group.id}>
              {item.study_group.name}
            </Option>
          ))}
        </Select>
      </div>
      <MainTable
        dataTable={students}
        columns={columns}
        // loading={loading}
        title={"Даалгаварын жагсаалт"}
      />
      <Modal
        title={"Даалгаварт үнэлгээ өгөх"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={handleSubmit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <div className="flex justify-start gap-20">
          <p className="font-bold ">
            <span className="font-normal">Cурагчийн нэр: </span>
            {selectedStudent?.fullName}
          </p>
          <p className="font-bold ">
            <span className="font-normal">Cурагчийн код: </span>
            {selectedStudent?.code}
          </p>
        </div>
        <div className="flex flex-col gap-5">
          <div className="flex items-center gap-2">
            <p
              className="p-0 m-0 font-bold cursor-pointer "
              onClick={() => {
                if (selectedAss?.fileLink) {
                  window.open(
                    selectedAss.fileLink,
                    "_blank",
                    "noopener,noreferrer"
                  );
                }
              }}
            >
              <span className="font-normal">Холбоос: </span>{" "}
              {selectedAss?.fileLink}
            </p>
            <button
              className="flex items-center gap-2 cursor-pointer"
              onClick={handleCopy}
            >
              <CopyOutlined />{" "}
              <span className={copy ? "" : "hidden"}>Copied!</span>
            </button>
          </div>
          <div>
            <p className="p-0 m-0 font-bold ">
              {" "}
              <span className="font-normal">Тайлбар: </span>{" "}
              {selectedAss?.description}
            </p>
          </div>
          <div className="flex items-center gap-5 ">
            <p className="m-0">Үнэлгээ өгөх:</p>
            <input
              value={point}
              onChange={(e) => setPoint(e.target.value)}
              placeholder="Оноо"
              type="number"
              className="px-2 text-center font-bold border-[2px] rounded-md w-14 "
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AssignmentResponse;
